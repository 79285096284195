<template>
    <div class="news">
        <nav-bar class="nav-bar" id="titleArea" ref="nav"></nav-bar>
        <div class="news-content d-flex" id="middleArea">
            <div class="content-right">
                <div class="right-desc" v-for="(item, index) in pagelist.list" :key="index">
                    <div class="desc-item" @click="details(item.id)">
                        <div class="flex-sbac">
                            <div class="title text-over">{{ item.title }}</div>
                            <div class="day">{{ item.createTime }}</div>
                        </div>                        
                        <div class="mt-11 content text-over-2" v-html='item.content'></div>  
                        <div class="mt-11 more">更多 >></div>                 
                    </div>
                </div>
                <div class="flex-acjc" style="width:100%;">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page.sync="currentPage" :page-size=this.pagesize layout="prev, pager, next, jumper"
                        :total=this.total>
                    </el-pagination>
                </div>
            </div>
        </div>
        <bottom-bar class="home-bottom" />
    </div>
</template>
<script>

import { Message, MessageBox } from 'element-ui'  //导入element-ui组件库
import { PageList, NewsInfo } from '@/api/news'
import { getInfo } from '@/api/login'

import NavBar from '../../components/content/navBar/NavBar'
import BottomBar from '../../components/content/bottomBar/BottomBar'

import {getToken,setToken,removeToken} from '@/utils/cookies.js'

export default {
    name: 'News',
    components: {
        NavBar,
        BottomBar
    },
    data() {
        return {
            currentPage: 1,//初始页      
            pagesize: 12,//每页的数据            
            users: {},//用户信息
            total: 0,//总数
            listQuery: {
                pageIndex: this.currentPage,
                pageSize: this.pagesize,
                newsTypeId: 1
            },
            pagelist: [],
        }
    },
    created() {
        //用户信息
        this.userInfo();
        //列表
        this.onPageList();
    },
    methods: {
        //查看详情
        details(id) {
            this.$router.push({
                name: '/newsDetail',
                params: {
                    "id": id,
                    "mdd": this.$refs.nav.mdd
                }
            });
        },
        //用户信息
        userInfo() {
            var token = getToken();
            if (token) {
                getInfo(token).then(response => {
                    if (response.status == 200) {
                        this.users = response.data;
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 1.5 * 1000)
                })
            }
        },
        //列表
        onPageList() {
            this.listLoading = true;
            this.listQuery.pageIndex = this.currentPage;
            this.listQuery.pageSize = this.pagesize;
            PageList(this.listQuery).then(response => {
                if (response) {
                    if (response.status == 200) {
                        this.pagelist = response.data;
                        this.total = response.data.total;
                    } else {
                        MessageBox.alert(response.message, "错误", {
                            confirmButtonText: '确定',
                            type: 'error'
                        })
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 1.5 * 1000)
                }
            })
        },
        //初始页currentPage、初始每页数据数pagesize和数据data
        handleSizeChange(size) {
            this.pagesize = size;
            this.onPageList();
        },
        //点击第几页
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.onPageList();
        }
    },
    mounted() {
        this.$refs.nav.mdd = 2;
        localStorage.setItem("mdd", 2);
        const titleHeight = document.getElementById('titleArea').clientHeight;
        const middleArea = document.getElementById('middleArea');
        const mheight = document.documentElement.clientHeight;
        middleArea.style.minHeight = (Number(mheight) - (Number(titleHeight) + 50)) + 'px';
    }
}
</script>
<style scoped lang="less">
.news {
    min-width: 1000px;

    .nav-bar {
        .bar-icon {
            border-radius: 50%;
            vertical-align: -4px;
            margin-right: 7px;
            width: 1.05rem;
            height: 1.05rem;
        }
    }
    .flex-sbac{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .news-content {
        padding-left: 9.1rem;
        padding-right: 9.1rem;
        margin-top: 1.1rem;
        padding-bottom: 1.8rem;
        .content-right {
            flex: 1;
            display: flex;
            flex-wrap: wrap;

            .right-desc {
                background-color: #fff;
                padding: 0.4rem 1.45rem 0.4rem;
                // padding: 0.4rem 0px;
                margin-bottom: 1.75rem;
                width:calc((100% - 20px) / 2);
                margin-right: 20px;
                border-bottom: 1px solid rgb(152,152,152);
                cursor: pointer;
                &:nth-child(2n){
                    margin-right: 0px;
                }

                .title {
                    color: #303133;
                    font-weight: 600;
                    font-size: 18px;
                    flex:1;
                }

                .content {
                    color: #6A6E76;
                    font-size: 14px;
                    line-height: 1.08rem;
                }

                .day {
                    color: #9C9DA0;
                    font-size: 14px;
                    width: 150px;
                    text-align: right;
                }

                .desc-item {
                    margin-top: 1rem;
                    border-bottom: 1px solid #E7E7E7;
                    padding-bottom: 1.08rem;

                    &:last-child {
                        border-bottom: none;
                    }
                }
                .more{
                    color: rgb(152,152,152);
                    border: 1px solid rgb(152,152,152);
                    width: fit-content;
                    border-radius: 5px;
                    padding: 3px 11px;
                    font-size: 14px;
                    cursor: pointer;
                    &:hover{
                        background-color: rgb(152,152,152);
                        color:#fff;
                    }
                }
            }
        }
    }
}
</style>